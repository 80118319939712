import Service from '@/config/service-identifiers';
import store from '@/store';
import { getPage, getTotal, storePage } from '@/store/store-utils/StorageUtils';
import PeopleAPI from '@/views/apps/people/store/PeopleAPI';

/**
 * People Store Module
 */
export default {
  namespaced: true,
  getters: {
    getPage: (state) => (
      ({
        typeKey,
        page,
        perPage,
      }) => (
        getPage({ page, perPage }, state, [typeKey])
      )
    ),
    getTotal: (state) => ({ typeKey }) => getTotal(state, [typeKey]),
    types: (state) => getPage({}, state, ['types']),
  },
  mutations: {
    storePage(state, {
      typeKey, data, meta,
    }) {
      storePage({ data, meta }, state, [typeKey]);
    },
    storeTypes(state, { data, meta }) {
      storePage({ data, meta }, state, ['types']);
    },
  },
  actions: {
    async fetchTypes(
      { getters, rootGetters, commit },
      { force, slug, key },
    ) {
      const storedPage = getters.types;
      if (storedPage && !force) {
        return storedPage;
      }

      const param = {};

      if(key){
        param.communityKey = key;
      }else{
        param.communitySlug = rootGetters.currentCollective.slug || slug;
      }

      const response = await store.$service[Service.BackendClient].get(
        'types',
        {
          params: {
            ...param,
            page: 1,
            count: 20,
            modelType: 'people',
          },
        },
      );

      commit('storeTypes', { ...response.data });

      return getters.types;
    },
    async fetchList(
      { commit, getters, rootGetters },
      {
        typeKey, page, perPage, force,
      },
    ) {
      const storedPage = getters.getPage({
        typeKey, page, perPage,
      });
      if (storedPage && !force) {
        return storedPage;
      }

      const response = await PeopleAPI.fetchPage({
        communityKey: rootGetters.currentCollective.key,
        typeKey,
        page,
        count: perPage,
      });

      commit('storePage', { typeKey, ...response });

      return getters.getPage({ typeKey, page, perPage });
    },
  },
};
