// import dependency to handle HTTP request to our back end
import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import Service from '@/config/service-identifiers';
import $services from '@/config/container';

export default {
  state: {
    chats: {},
    globalChat: {
      paginated: {},
      unpaginated: [],
    },
  },
  getters: {
    chats: (state) => state.chats,
    globalChat: (state) => state.globalChat,
  },
  mutations: {
    SET_ITEMS_SOCKETS(state, payload) {
      if (payload.storedKey == null) {
        // En caso de que no sea un fragmentado guardaremos el paginado y el no paginado junto al meta.
        Vue.set(
          state[payload.type].paginated, 1,
          [...payload.data.data],
        );

        state[payload.type].unpaginated = [...state[payload.type].unpaginated, ...payload.data.data];
        Vue.set(state[payload.type], 'meta', { ...payload.data.meta });
      } else {
        // console.log('payload', payload);
        // En caso de que sea un fragmentado comprobamos si existe.
        if (state[payload.type][payload.storedKey] == null) {
          // En caso de no existir se inicializa.
          Vue.set(state[payload.type], payload.storedKey, { unpaginated: {} });
        }
        if (payload.force === true) {
          Vue.set(state[payload.type][payload.storedKey], 'unpaginated', []);
        }
        // En caso de existir no hace falta inicializarlo, sigue adelante.
        if (state[payload.type][payload.storedKey].unpaginated.length == null) {
          if (payload.data.data instanceof Array) {
            state[payload.type][payload.storedKey].unpaginated = [...payload.data.data];
            Vue.set(state[payload.type][payload.storedKey], 'unpaginated', [...payload.data.data]);
          } else {
            state[payload.type][payload.storedKey].unpaginated = [payload.data.data];
            Vue.set(state[payload.type][payload.storedKey], 'unpaginated', [payload.data.data]);
          }
        } else if (payload.data.data instanceof Array) {
          Vue.set(state[payload.type][payload.storedKey], 'unpaginated', [...state[payload.type][payload.storedKey].unpaginated, ...payload.data.data]);
        } else {
          Vue.set(state[payload.type][payload.storedKey], 'unpaginated', [...state[payload.type][payload.storedKey].unpaginated, payload.data.data]);
        }
        Vue.set(state[payload.type][payload.storedKey], 'meta', payload.data.data);
      }
    },
  },
  actions: {
    getItemsSocket({ commit, state, rootState }, {
      itemType, perPage, requestConfig, storedKey,
    }) {
      return new Promise((resolve) => {
        const communitySlug = rootState.collective.collective.slug;

        $services[Service.Sockets]
          .get(`/api/v1/${itemType}`, {
            params: {
              communitySlug: communitySlug,
              count: perPage,
              ...requestConfig,
            },
          })
          .then((response) => {
            commit('SET_ITEMS_SOCKETS', {
              type: itemType, storedKey, data: response.data, force: true,
            });
            resolve(response.data);
          })
          .catch((e) => {
            console.log(e);

            //TODO: delete on production
            const fakeResponse = {
              "data": [
                {
                  "memberData": {
                    "key": "54e7f9ad-4e8e-4112-b000-7003bec2e68a",
                    "username": "pantaleon.ferrer",
                    "name": "Pantaleon",
                    "surname": "Ferrer Valls",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/YZci3pgRDC1iDHgjyR5061870005.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=d2b5a7ba9dfa1bed0e219d7116d2e81f3bcb37db79d600e74c0034afbf1c619c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1649421106793,
                  "message": "<p>Test</p>"
                },
                {
                  "memberData": {
                    "key": "54e7f9ad-4e8e-4112-b000-7003bec2e68a",
                    "username": "pantaleon.ferrer",
                    "name": "Pantaleon",
                    "surname": "Ferrer Valls",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/YZci3pgRDC1iDHgjyR5061870005.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=d2b5a7ba9dfa1bed0e219d7116d2e81f3bcb37db79d600e74c0034afbf1c619c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1649422144915,
                  "message": "<p>Message</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=b07a47c3f70b5817044276b0278fde7792477f26c99e2888d2d18e06c23c6747&X-Amz-SignedHeaders=host"
                  },
                  "date": 1649451563595,
                  "message": "<p>holaa</p>"
                },
                {
                  "memberData": {
                    "key": "54e7f9ad-4e8e-4112-b000-7003bec2e68a",
                    "username": "pantaleon.ferrer",
                    "name": "Pantaleon",
                    "surname": "Ferrer Valls",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/YZci3pgRDC1iDHgjyR5061870005.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=d2b5a7ba9dfa1bed0e219d7116d2e81f3bcb37db79d600e74c0034afbf1c619c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1651658565115,
                  "message": "<p>Nuevo mensaje</p>"
                },
                {
                  "memberData": {
                    "key": "54e7f9ad-4e8e-4112-b000-7003bec2e68a",
                    "username": "pantaleon.ferrer",
                    "name": "Pantaleon",
                    "surname": "Ferrer Valls",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/YZci3pgRDC1iDHgjyR5061870005.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=d2b5a7ba9dfa1bed0e219d7116d2e81f3bcb37db79d600e74c0034afbf1c619c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1651658618597,
                  "message": "<p>Mandado 4/5 a las 12:03</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=b07a47c3f70b5817044276b0278fde7792477f26c99e2888d2d18e06c23c6747&X-Amz-SignedHeaders=host"
                  },
                  "date": 1651666194951,
                  "message": "<p>Hola Panta!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=b07a47c3f70b5817044276b0278fde7792477f26c99e2888d2d18e06c23c6747&X-Amz-SignedHeaders=host"
                  },
                  "date": 1652094716548,
                  "message": "<p>holaa! hay alguien por aqui?</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=b07a47c3f70b5817044276b0278fde7792477f26c99e2888d2d18e06c23c6747&X-Amz-SignedHeaders=host"
                  },
                  "date": 1652444684236,
                  "message": "<p>hola!</p>"
                },
                {
                  "memberData": {
                    "key": "8d7901cb-8281-4fc3-ab1e-d7647cb54d49",
                    "username": "victor.valero",
                    "name": "Victor",
                    "surname": "Valero",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/vuyt5aERGKBpD0p0pJ9Bfoto_Victor.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=197492d81726c928568750e14cd052dad22b146d561d97c879b7fbb43d675fe2&X-Amz-SignedHeaders=host"
                  },
                  "date": 1652704564218,
                  "message": "<p>bon dia</p>"
                },
                {
                  "memberData": {
                    "key": "449e38b6-28c5-4132-9c16-c158e88f677e",
                    "username": "xavicreusceo",
                    "name": "Xavier",
                    "surname": "Creus",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/2/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=8256e9179855ecc93c51d73a483ae0c288ec1b71bcdc55ab7b918fd51276b42b&X-Amz-SignedHeaders=host"
                  },
                  "date": 1654783266520,
                  "message": "<p>Hooola! :)</p>"
                },
                {
                  "memberData": {
                    "key": "449e38b6-28c5-4132-9c16-c158e88f677e",
                    "username": "xavicreusceo",
                    "name": "Xavier",
                    "surname": "Creus",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/2/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=8256e9179855ecc93c51d73a483ae0c288ec1b71bcdc55ab7b918fd51276b42b&X-Amz-SignedHeaders=host"
                  },
                  "date": 1654783288431,
                  "message": "<p>No me va el chat</p>"
                },
                {
                  "memberData": {
                    "key": "449e38b6-28c5-4132-9c16-c158e88f677e",
                    "username": "xavicreusceo",
                    "name": "Xavier",
                    "surname": "Creus",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/2/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181418Z&X-Amz-Expires=1800&X-Amz-Signature=8256e9179855ecc93c51d73a483ae0c288ec1b71bcdc55ab7b918fd51276b42b&X-Amz-SignedHeaders=host"
                  },
                  "date": 1654783297941,
                  "message": ""
                },
                {
                  "memberData": {
                    "key": "449e38b6-28c5-4132-9c16-c158e88f677e",
                    "username": "xavicreusceo",
                    "name": "Xavier",
                    "surname": "Creus",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/2/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=ee6cf261e2cf11f8db1289e1c1814a14b7d04303f1c46ce0c774964d632bae97&X-Amz-SignedHeaders=host"
                  },
                  "date": 1654783299519,
                  "message": "<p>dedede</p>"
                },
                {
                  "memberData": {
                    "key": "449e38b6-28c5-4132-9c16-c158e88f677e",
                    "username": "xavicreusceo",
                    "name": "Xavier",
                    "surname": "Creus",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/2/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=ee6cf261e2cf11f8db1289e1c1814a14b7d04303f1c46ce0c774964d632bae97&X-Amz-SignedHeaders=host"
                  },
                  "date": 1654783300228,
                  "message": ""
                },
                {
                  "memberData": {
                    "key": "449e38b6-28c5-4132-9c16-c158e88f677e",
                    "username": "xavicreusceo",
                    "name": "Xavier",
                    "surname": "Creus",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/2/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=ee6cf261e2cf11f8db1289e1c1814a14b7d04303f1c46ce0c774964d632bae97&X-Amz-SignedHeaders=host"
                  },
                  "date": 1654783300424,
                  "message": ""
                },
                {
                  "memberData": {
                    "key": "449e38b6-28c5-4132-9c16-c158e88f677e",
                    "username": "xavicreusceo",
                    "name": "Xavier",
                    "surname": "Creus",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/2/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=ee6cf261e2cf11f8db1289e1c1814a14b7d04303f1c46ce0c774964d632bae97&X-Amz-SignedHeaders=host"
                  },
                  "date": 1654783300584,
                  "message": ""
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=a28860f24f03bd3c33ffad97c6a702152136c5cd24f70f021485204208ca497a&X-Amz-SignedHeaders=host"
                  },
                  "date": 1655793877850,
                  "message": "<p>bon dia!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=a28860f24f03bd3c33ffad97c6a702152136c5cd24f70f021485204208ca497a&X-Amz-SignedHeaders=host"
                  },
                  "date": 1655794259986,
                  "message": "<p>això va?</p>"
                },
                {
                  "memberData": {
                    "key": "57bf8a52-80f0-4660-9187-93782bef8cac",
                    "username": "alan.riemer",
                    "name": "Alan",
                    "surname": "Riemer",
                    "avatarURL": null
                  },
                  "date": 1655796504296,
                  "message": "<p>O.O</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=a28860f24f03bd3c33ffad97c6a702152136c5cd24f70f021485204208ca497a&X-Amz-SignedHeaders=host"
                  },
                  "date": 1655808689880,
                  "message": "<p>funcionaa?</p>"
                },
                {
                  "memberData": {
                    "key": "54e7f9ad-4e8e-4112-b000-7003bec2e68a",
                    "username": "pantaleon.ferrer",
                    "name": "Pantaleon",
                    "surname": "Ferrer Valls",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/YZci3pgRDC1iDHgjyR5061870005.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=c5b9d9106de447da826dd16f9d7644f5a463af8949535aa0e34ae3e150536349&X-Amz-SignedHeaders=host"
                  },
                  "date": 1655883683678,
                  "message": "<p>Test</p>"
                },
                {
                  "memberData": {
                    "key": "ac099080-f8ed-40b5-8dc8-6ed4d5e094b9",
                    "username": "hector.montesinos",
                    "name": "Héctor",
                    "surname": "Montesinos",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/7JRQEVTLdyKEXZdPkbPeCaptura_de_pantalla_de_2022-01-22_18-23-30.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=806b974f4a5019680d44bf52398d6f3e623a0383554b597b0f7cb8cf45a7ac1b&X-Amz-SignedHeaders=host"
                  },
                  "date": 1655883941111,
                  "message": "<p>prueba</p>"
                },
                {
                  "memberData": {
                    "key": "54e7f9ad-4e8e-4112-b000-7003bec2e68a",
                    "username": "pantaleon.ferrer",
                    "name": "Pantaleon",
                    "surname": "Ferrer Valls",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/YZci3pgRDC1iDHgjyR5061870005.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=c5b9d9106de447da826dd16f9d7644f5a463af8949535aa0e34ae3e150536349&X-Amz-SignedHeaders=host"
                  },
                  "date": 1655884124698,
                  "message": "<p>Test</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=a28860f24f03bd3c33ffad97c6a702152136c5cd24f70f021485204208ca497a&X-Amz-SignedHeaders=host"
                  },
                  "date": 1655884407583,
                  "message": "<p>Bon dia!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=a28860f24f03bd3c33ffad97c6a702152136c5cd24f70f021485204208ca497a&X-Amz-SignedHeaders=host"
                  },
                  "date": 1655884418426,
                  "message": "<p>Ahora parece que si que funciona bien el chat</p>"
                },
                {
                  "memberData": {
                    "key": "ac099080-f8ed-40b5-8dc8-6ed4d5e094b9",
                    "username": "hector.montesinos",
                    "name": "Héctor",
                    "surname": "Montesinos",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/7JRQEVTLdyKEXZdPkbPeCaptura_de_pantalla_de_2022-01-22_18-23-30.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=806b974f4a5019680d44bf52398d6f3e623a0383554b597b0f7cb8cf45a7ac1b&X-Amz-SignedHeaders=host"
                  },
                  "date": 1656428252179,
                  "message": "<p>Yeee</p>"
                },
                {
                  "memberData": {
                    "key": "ac099080-f8ed-40b5-8dc8-6ed4d5e094b9",
                    "username": "hector.montesinos",
                    "name": "Héctor",
                    "surname": "Montesinos",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/7JRQEVTLdyKEXZdPkbPeCaptura_de_pantalla_de_2022-01-22_18-23-30.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=806b974f4a5019680d44bf52398d6f3e623a0383554b597b0f7cb8cf45a7ac1b&X-Amz-SignedHeaders=host"
                  },
                  "date": 1656428273653,
                  "message": "<p>Sergio estás?</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=a28860f24f03bd3c33ffad97c6a702152136c5cd24f70f021485204208ca497a&X-Amz-SignedHeaders=host"
                  },
                  "date": 1656932325461,
                  "message": "<p>hola! estaría bien que se viera la fecha de los mensajes</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=a28860f24f03bd3c33ffad97c6a702152136c5cd24f70f021485204208ca497a&X-Amz-SignedHeaders=host"
                  },
                  "date": 1664798159688,
                  "message": "<p>buenas team! vamos a darle caña! feliz semana a todos!!!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181419Z&X-Amz-Expires=1800&X-Amz-Signature=a28860f24f03bd3c33ffad97c6a702152136c5cd24f70f021485204208ca497a&X-Amz-SignedHeaders=host"
                  },
                  "date": 1673339432254,
                  "message": "<p>bon diaa</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1673354343481,
                  "message": "<p>holaa</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1673603924883,
                  "message": "<p>holaaa</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1673603928591,
                  "message": "<p>helloooo</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1673617608744,
                  "message": "<p>holaaa</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1673617613820,
                  "message": "<p>que bien que esto ya funciona</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1674143090032,
                  "message": "<p>holaa!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1674143109819,
                  "message": "<p>no va muy fino el chat, al darle a enter me desaparece el mensaje pero no lo veo</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1674143111204,
                  "message": ""
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1674143142788,
                  "message": "<p>al refrescar pantalla entonces sí que lo he visto</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1674635337582,
                  "message": "<p>bon dia!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1674750895342,
                  "message": "<p>holaaa! alguien me lee?</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1675679484902,
                  "message": "<p>Buenos días! Iniciamos la semana 6!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1675772325239,
                  "message": "<p>holaaa!!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1675772336580,
                  "message": "<p>acabamos de subir a producción la nueva versión de Nectios</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1675974902273,
                  "message": "<p>holaa!</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1675974913457,
                  "message": "<p>qué ganas de tener notificaciones en los chats</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1677077273974,
                  "message": "<p>holaaa!</p>"
                },
                {
                  "memberData": {
                    "key": "42b863bf-a2f4-4819-92ee-1f2f6d54e58c",
                    "username": "Mhinojo",
                    "name": "Miriam",
                    "surname": "Hinojo",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/Sg8gfhLO63jKpy4Jw5Ao1580916634007_1.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=a569855496b81d78cad8d7a217824c82b776b63f8e21dc13565a51a005305710&X-Amz-SignedHeaders=host"
                  },
                  "date": 1677077287526,
                  "message": "<p>hola</p>"
                },
                {
                  "memberData": {
                    "key": "ac099080-f8ed-40b5-8dc8-6ed4d5e094b9",
                    "username": "hector.montesinos",
                    "name": "Héctor",
                    "surname": "Montesinos",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/7JRQEVTLdyKEXZdPkbPeCaptura_de_pantalla_de_2022-01-22_18-23-30.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=ba7713e351753d5ba79764ef029ed7dfb55ef7b15cc3f8b7cbb36bc87e61c663&X-Amz-SignedHeaders=host"
                  },
                  "date": 1677077290359,
                  "message": "<p>ey</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1693498097573,
                  "message": "buenos díasssss!"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181420Z&X-Amz-Expires=1800&X-Amz-Signature=82b51f84188a5a7ae80971bac4a6f7de13c064846bde17243223583a4729c151&X-Amz-SignedHeaders=host"
                  },
                  "date": 1693498105131,
                  "message": "como va todo por aquí?"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1693817909256,
                  "message": "holaa!"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1693817919913,
                  "message": "parece que el chat si que va"
                },
                {
                  "memberData": {
                    "key": "2d331206-1a27-455c-a2fc-ef4e5a8787dc",
                    "username": "nectios.success",
                    "name": "Nectios",
                    "surname": "Success",
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/images/GGjbDbPxioqSWP2obLRrNectios_Logo_Symbol_Light_RGB.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=e90e233d4b019826904af85087bb9a154acf091a79f8677055770082adb61630&X-Amz-SignedHeaders=host"
                  },
                  "date": 1693817997452,
                  "message": "hi! aquí sí"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1701287591101,
                  "message": "ieeep"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1712583064485,
                  "message": "holaaaa!!! este es un mensaje para el grupo de chat general de Nectios"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1712583129507,
                  "message": "holaaaa"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1712585781171,
                  "message": "vale por fin salen los mensajes!"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1712586057372,
                  "message": "test"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1712586060229,
                  "message": "testtttt"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1712586849170,
                  "message": "helloooooooo"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1712586877920,
                  "message": "yihaaa"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716548127445,
                  "message": "<p>eppaaaaa</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716548165209,
                  "message": "<p>hola</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716550780430,
                  "message": "<p>adfgsdfgsdfg</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181421Z&X-Amz-Expires=1800&X-Amz-Signature=d229b1b5c849e0a91589f7c05529fbc5db25e6773a58eaecf7549223f3ae311d&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716550832723,
                  "message": "<p>holaaa</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181422Z&X-Amz-Expires=1800&X-Amz-Signature=ae333bf8bb52d9dbceb54a164b105865f01f7f5f9d4f90d5a9c40898b0f984bf&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716550850595,
                  "message": "<p>dfgsdf</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181422Z&X-Amz-Expires=1800&X-Amz-Signature=ae333bf8bb52d9dbceb54a164b105865f01f7f5f9d4f90d5a9c40898b0f984bf&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716550855831,
                  "message": "<p>dfghdf</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181422Z&X-Amz-Expires=1800&X-Amz-Signature=ae333bf8bb52d9dbceb54a164b105865f01f7f5f9d4f90d5a9c40898b0f984bf&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716550869188,
                  "message": "<p>hola</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181422Z&X-Amz-Expires=1800&X-Amz-Signature=ae333bf8bb52d9dbceb54a164b105865f01f7f5f9d4f90d5a9c40898b0f984bf&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716550879023,
                  "message": "<p>asas</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181422Z&X-Amz-Expires=1800&X-Amz-Signature=ae333bf8bb52d9dbceb54a164b105865f01f7f5f9d4f90d5a9c40898b0f984bf&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716552626257,
                  "message": "<p>jjjjj</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181422Z&X-Amz-Expires=1800&X-Amz-Signature=ae333bf8bb52d9dbceb54a164b105865f01f7f5f9d4f90d5a9c40898b0f984bf&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716552635063,
                  "message": "<p>kolllk</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181422Z&X-Amz-Expires=1800&X-Amz-Signature=ae333bf8bb52d9dbceb54a164b105865f01f7f5f9d4f90d5a9c40898b0f984bf&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716552809053,
                  "message": "<p>asdfasdfgasdfgasfdg</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181423Z&X-Amz-Expires=1800&X-Amz-Signature=4329d02357fb60163ed921d897b64fb25a5f4ded957cb03a2b12ce6cbf865d9c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716552825180,
                  "message": "<p>sdfgsd</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181423Z&X-Amz-Expires=1800&X-Amz-Signature=4329d02357fb60163ed921d897b64fb25a5f4ded957cb03a2b12ce6cbf865d9c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716555970747,
                  "message": "<p>este chat da para enviar mail</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181423Z&X-Amz-Expires=1800&X-Amz-Signature=4329d02357fb60163ed921d897b64fb25a5f4ded957cb03a2b12ce6cbf865d9c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716556079949,
                  "message": "<p>otro</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181423Z&X-Amz-Expires=1800&X-Amz-Signature=4329d02357fb60163ed921d897b64fb25a5f4ded957cb03a2b12ce6cbf865d9c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716556170711,
                  "message": "<p>messsaasasa</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181423Z&X-Amz-Expires=1800&X-Amz-Signature=4329d02357fb60163ed921d897b64fb25a5f4ded957cb03a2b12ce6cbf865d9c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716557325062,
                  "message": "<p>holaaaaa</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181423Z&X-Amz-Expires=1800&X-Amz-Signature=4329d02357fb60163ed921d897b64fb25a5f4ded957cb03a2b12ce6cbf865d9c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716557330372,
                  "message": "<p>que tal?</p>"
                },
                {
                  "memberData": {
                    "key": "960554b3-043d-4933-9339-f3f5cdb5ae2f",
                    "username": "sergio.pulido",
                    "name": "",
                    "surname": null,
                    "avatarURL": "https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/users/11/profile.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2H3RKOQOSLLMJO6T%2F20240929%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20240929T181423Z&X-Amz-Expires=1800&X-Amz-Signature=4329d02357fb60163ed921d897b64fb25a5f4ded957cb03a2b12ce6cbf865d9c&X-Amz-SignedHeaders=host"
                  },
                  "date": 1716557335606,
                  "message": "<p>como va todo?</p>"
                }
              ],
              "total": 82
            };

            // console.log('fakeResponse.data.length', fakeResponse.data.length);

            commit('SET_ITEMS_SOCKETS', {
              type: itemType, storedKey, data: fakeResponse.data, force: true,
            });
            resolve(fakeResponse.data);
            // until here
            
          });
      });
    },
    setItemSocket({ commit, state, rootState }, { itemType, item, storedKey }) {
      commit('SET_ITEMS_SOCKETS', {
        type: itemType, storedKey, data: { data: item },
      });

      return item;
    },
  },
};
