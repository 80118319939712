export default {
  state: {
    settingsMenu: false,
    editing: false,
  },
  getters: {},
  mutations: {
    OPEN_SETTINGS_MENU(state) {
      state.settingsMenu = true;
    },
    CLOSE_SETTINGS_MENU(state) {
      state.settingsMenu = false;
    },
    EDIT(state) {
      state.editing = !state.editing;
    },
  },
  actions: {
    openSettingsMenu({ commit }) {
      commit('OPEN_SETTINGS_MENU');
    },
    closeSettingsMenu({ commit }) {
      commit('CLOSE_SETTINGS_MENU');
    },
    edit({ commit }) {
      commit('EDIT');
    },
  },
};
