import { DeviceType, calculateEndpointHash as commonsCalculateEndpointHash } from '@copernicsw/community-common';
import Service from '@/config/service-identifiers';
import $service from '@/config/container';

async function calculateEndpointHash(subscription) {
  const uint8Array = await commonsCalculateEndpointHash(subscription.endpoint);

  return uint8Array.toString('base64');
}

export default {
  /**
   * Send a request to the server to update user's subscription.
   *
   * @param {PushSubscription} subscription
   */
  async updateSubscription(subscription) {
    const key = subscription.getKey('p256dh');
    const token = subscription.getKey('auth');
    const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

    const data = {
      endpoint: subscription.endpoint,
      publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
      authSecret: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
      contentEncoding,
      expirationTime: subscription.expirationTime,
      deviceType: DeviceType.computer,
    };

    await $service[Service.BackendClient].post('/subscribePush', data);
  },
  /**
   * Send a request to the server to notify the user's subscription is active.
   *
   * @param {PushSubscription} subscription
   */
  async notifyActiveSubscription(subscription) {
    await $service[Service.BackendClient].get('/notifyPushSubscriptionActive', {
      params: {
        hashedEndpoint: await calculateEndpointHash(subscription),
      },
    });
  },
  /**
   * Send a request to the server to delete user's subscription.
   *
   * @param {PushSubscription} subscription
   */
  async deleteSubscription(subscription) {
    await $service[Service.BackendClient].post('/removePushSubscriptionStatus', {
      hashedEndpoint: await calculateEndpointHash(subscription),
    });
  },

  async fetchNotificationsCount({ communityKey }) {
    try {
      const response = await $service[Service.BackendClient].get('/countUnreadNotifications',
        { params: { communityKey } });

      return response.data;
    } catch (error) {
      return 0;
    }
  },
};
