/* eslint-disable implicit-arrow-linebreak */
import store from "@/store";

export default async function ElementsListWidgetsFactory() {
  const elements = store.getters.currentCollective?.elements;
  if(elements == null){
    return {};
  }
  const arrayElements = Object.values(elements);

  const elementsByType = {};

   arrayElements.map((element) => {

    if(element.isVisible){

      elementsByType[element.key] = {
        key: element.key,
      loadComponent: () =>
        import("@/views/apps/elements/widgets/ElementsListWidget.vue"),
      props: { element }
      };

    }

  });

  return elementsByType;
}
