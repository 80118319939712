import store from '@/store';

export default async function ChallengesByTypeListWidgetsFactory() {
  const { apps } = store.getters;

  const showTypes = apps.apps[24]?.customization?.displayOptions || 1;

  let types = [];
  await store.dispatch('getItems', {
    itemType: 'types',
    storedKey: 'challenges',
    forceAPICall: true,
    page: 1,
    perPage: 200,
    requestConfig: {
      modelType: 'challenge',
      getCommunityTypes: 1,
      selectAll: 1,
    },
  });

  if (showTypes === 2 || showTypes === 3) {
    types = store.getters.types?.challenges?.unpaginated || [];
  }
  if (showTypes === 1 || showTypes === 3) {
    types.push({ name: 'Challenge', key: 0, namePlural: { ca: 'Reptes', es: 'Retos', en: 'Challenges' } });
  }
  return types.reduce(
    (widgetsMap, type) => ({
      ...widgetsMap,
      [type.key === 0 ? 'challenges' : `challenges-${type.key}`]: {
        loadComponent: () =>
          // eslint-disable-next-line implicit-arrow-linebreak
          import(
            '@/views/apps/challenges/widgets/ChallengesListWidget.vue'
          ),
        props: { type },
      },
    }),
    {},
  );
}
