import store from "@/store";

export default async function EventsByTypeListWidgetsFactory() {
  const { apps } = store.getters;

  const showTypes = apps.apps[96]?.customization?.displayOptions;
  const types = [];

  if (showTypes === 1) {
    types.push({
      name: "present",
      key: 1,
      namePlural: { ca: "Actuals", es: "Actuales", en: "Present" },
    });
    types.push({
      name: "futur",
      key: 3,
      namePlural: { ca: "Propers", es: "Próximos", en: "Upcoming" },
    });
    types.push({
      name: "past",
      key: 4,
      namePlural: { ca: "Passats", es: "Pasados", en: "Past" },
    });
  } else if (showTypes === 2) {
    types.push({
      name: "present-futur",
      key: 2,
      namePlural: {
        ca: "Actuals i propers",
        es: "Actuales i próximos",
        en: "Present and upcoming",
      },
    });
    types.push({
      name: "past",
      key: 4,
      namePlural: { ca: "Passats", es: "Pasados", en: "Past" },
    });
  } else {
    types.push({
      name: "events",
      key: 0,
      namePlural: { ca: "Esdeveniments", es: "Eventos", en: "Events" },
    });
  }

  return types.reduce(
    (widgetsMap, type) => ({
      ...widgetsMap,
      [type.name]: {
        loadComponent: () =>
          // eslint-disable-next-line implicit-arrow-linebreak
          import(
            "@/views/apps/membershipEvents/widgets/MembershipEventsListWidget.vue"
          ),
        props: { type },
      },
    }),
    {}
  );
}
