import Service from '@/config/service-identifiers';
import $service from '@/config/container';
import OrderBy from '@/@core/constants/OrderBy';

export default {
  async fetchPage({
    page, perPage, communityKey, typeKey, eventKey, searchString,
  }) {
    const response = await $service[Service.BackendClient].get(
      'people',
      {
        params: {
          orderByCustomOrder: OrderBy.DESC,
          orderBySurname: OrderBy.ASC,
          communityKey: communityKey,
          page,
          count: perPage,
          typeKey,
          eventKey,
          searchString,
        },
      },
    );

    return response.data;
  },
};
