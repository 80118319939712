import { urlBase64ToUint8Array } from '@/@core/utils/url-utils';
import $service from '@/config/container';
import Service from '@/config/service-identifiers';
import notificationsAPI from '@/store/notifications/notificationsAPI';

/**
 * Store module for the notifications
 */
export default {
  namespaced: true,
  state: {
    isSubscribedToPushNotifications: false,
    areNotificationsBlocked: false,
    isSubscribing: false,

    unreadCount: 0,

    // function used to subscribe and unsubscribe to the message event listener
    eventListener: null,

    isPolling: false,
    pollingTimeoutId: null,

    forceReload: false,
    
    notifications: [],
  },
  getters: {
    isSubscribedToPushNotifications({ isSubscribedToPushNotifications }) {
      return isSubscribedToPushNotifications;
    },
    unreadCount({ unreadCount }) {
      return unreadCount;
    },
    eventListener({ eventListener }) {
      return eventListener;
    },
    forceReload({ forceReload }) {
      return forceReload;
    },
  },
  mutations: {
    setSubscriptionStatus(state, { isSubscribedToPushNotifications, areNotificationsBlocked }) {
      state.isSubscribedToPushNotifications = !!isSubscribedToPushNotifications;
      state.areNotificationsBlocked = !!areNotificationsBlocked;
    },
    increaseUnreadCount(state) {
      state.unreadCount += 1;
    },
    decreaseUnreadCount(state) {
      state.unreadCount -= 1;
    },
    resetCount(state) {
      state.unreadCount = 0;
    },
    setEventListener(state, eventListener) {
      state.eventListener = eventListener;
    },
    setUnreadCount(state, unreadCount) {
      state.unreadCount = unreadCount;
    },
    setForceReload(state, forceReload) {
      state.forceReload = forceReload;
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
    }
  },
  actions: {
    unreadCount({commit}, count){
      commit("setUnreadCount", count)
    },
    /**
     * Initialize Service Worker for PWA and push notifications
     */
    async initializeServiceWorker({ dispatch, commit }) {
      console.log('Initializing service worker...');
      // console.log('window.Notification', window.Notification);
      if (!window.Notification) {
        console.log('Notifications not supported')
        // not supported
        return;
      }
      if (window.Notification.permission === 'denied') {
        console.log('Notifications permission denied')
        commit('setSubscriptionStatus', { areNotificationsBlocked: true });
        return;
      }

      const registration = await window.navigator.serviceWorker.ready;

      if (!registration?.pushManager) return;

      const subscription = await registration.pushManager.getSubscription();
      commit('setSubscriptionStatus', {
        isSubscribedToPushNotifications: subscription !== null,
      });

      // Notify Subscription is active
      try {
        await notificationsAPI.notifyActiveSubscription(subscription);
        dispatch('subscribeToNotificationEvents');
      } catch (error) {
        commit('setSubscriptionStatus', {
          isSubscribedToPushNotifications: false,
        });
      }
    },
    /**
     * Change the status of the subscription to push notifications
     */
    async toggleNotifications({ state, commit, dispatch }, subscribe) {
      console.log('toggleNotifications subscribe', subscribe);
      if (subscribe) {
        if (state.areNotificationsBlocked) {
          console.log("Notifications are blocked, can't subscribe.")
          commit('setSubscriptionStatus', { areNotificationsBlocked: true });
          throw new Error('notifications-blocked');
        }
        return dispatch('subscribe');
      }

      return dispatch('unsubscribe');
    },
    /**
     * 
     */
    notificationDismissed({ commit }) {
      commit('decreaseUnreadCount');
    },
    /**
     * 
     */
    allNotificationsDismissed({ commit }) {
      commit('resetCount');
    },
    /**
     * 
     */
    requestForceReload({ commit }) {
      commit('setForceReload', true);
    },
    /**
     * Subscribe for push notifications.
     */
    async subscribe({ dispatch, commit }) {
      console.log('##### Subscribe to push notifications')
      const response = await $service[Service.BackendClient].get('publicVAPID');
      const registration = await window.navigator.serviceWorker.ready;

      try {
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(response.data.publicKey),
        });

        console.log('setSubscriptionStatus to true');

        commit('setSubscriptionStatus', { isSubscribedToPushNotifications: true });

        await notificationsAPI.updateSubscription(subscription);
        dispatch('subscribeToNotificationEvents');

        return 'subscription-success';
      } catch (error) {
        if (window.Notification.permission === 'denied') {
          commit('setSubscriptionStatus', { areNotificationsBlocked: true });

          throw new Error('permission-denied');
        } else {
          commit('setSubscriptionStatus', { isSubscribedToPushNotifications: true });

          throw new Error('subscription-error');
        }
      }
    },
    /**
     * Unsubscribe from push notifications.
     */
    async unsubscribe({ dispatch, commit }) {
      console.log('##### Unsubscribe from push notifications')
      try {
        const registration = await window.navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        if (!subscription) {
          return '';
        }

        await notificationsAPI.deleteSubscription(subscription);
        await subscription.unsubscribe();
        commit('setSubscriptionStatus', {});

        dispatch('unsubscribeToNotificationEvents');

        return 'unsubscription-success';
      } catch (error) {
        throw new Error('unsubscription-error');
      }
    },
    subscribeToNotificationEvents({ commit, getters }) {
      commit('setEventListener', () => {
        commit('increaseUnreadCount');
      });
      navigator.serviceWorker.addEventListener('message', getters.eventListener);
    },
    unsubscribeToNotificationEvents({ commit, getters }) {
      navigator.serviceWorker.removeEventListener('message', getters.eventListener);
      commit('setEventListener', null);
    },
    setNotifications({ commit }, notifications) {
      commit('SET_NOTIFICATIONS', notifications);
    }
  },
};
