import store from '@/store';

export default async function MembersListWidgetsFactory() {
  let members = store.getters.communityMembersByType.unpaginated || [];

  if (members && members.length === 0) {
    await store.dispatch('getItems', {
      itemType: 'communityMembersByType',
    });
    members = store.getters.communityMembersByType.unpaginated || [];
  }

  if (members.length === 0) {
    return {
      'member-empty': {
        loadComponent: () => import('@/views/apps/member/widgets/MembersListWidget.vue' /* webpackChunkName: "MembersListWidget" */),
      },
    };
  }

  let finalArray = [];

  try{

    for (const keys in members) {
      if (members[keys]?.totalMembers !== 0) {
        finalArray.push(members)
      }
    }

    const membersByType = finalArray.map((type) => ({

      loadComponent: () => import('@/views/apps/member/widgets/MembersByTypeListWidget.vue' /* webpackChunkName: "MembersByTypeListWidget" */),
      props: { type },

    }));
    return membersByType;

  }catch(e){
    console.log(e)
  }
}
