import VideosPlaceholder from "@/assets/images/placeholders/light/videos.svg";
import AudiosPlaceholder from "@/assets/images/placeholders/light/audios.svg";
import PresentationsPlaceholder from "@/assets/images/placeholders/light/presentations.svg";
import PhotosPlaceholder from "@/assets/images/placeholders/light/photo.svg";
import SpreadsheetPlaceholder from "@/assets/images/placeholders/light/excel.svg";
import DocumentsPlaceholder from "@/assets/images/placeholders/light/posts.svg";

import VideosItemPlaceholder from "@/assets/images/placeholders/light/video-item.svg";
import AudiosItemPlaceholder from "@/assets/images/placeholders/light/audios.svg";
import PresentationsItemPlaceholder from "@/assets/images/placeholders/light/presentation-item.svg";
import PhotosItemPlaceholder from "@/assets/images/placeholders/light/photo.svg";
import SpreadsheetItemPlaceholder from "@/assets/images/placeholders/light/excel-item.svg";
import FilePlaceholder from "@/assets/images/placeholders/light/banner_file.png";
import DocumentsItemPlaceholder from "@/assets/images/placeholders/light/post-item.svg";

const MediaType = Object.freeze({
  photo: "photo",
  subtitles: "subtitles",
  document: "document",
  spreadsheet: "spreadsheet",
  application: "application",
  video: "video",
  presentation: "presentation",
  audio: "audio",
  storage: "file",
});

export function PlaceholdersImage(mediaType) {
  switch (mediaType) {
    case MediaType.video:
      return VideosPlaceholder;
    case MediaType.presentation:
      return PresentationsPlaceholder;
    case MediaType.photo:
      return PhotosPlaceholder;
    case MediaType.document:
      return DocumentsPlaceholder;
    case MediaType.spreadsheet:
      return SpreadsheetPlaceholder;
    case MediaType.storage:
      return FilePlaceholder;
    case MediaType.audio: // There is no audio placeholder, the video placeholder has been put in its place
      return AudiosPlaceholder;
    default:
      return PhotosPlaceholder;
  }
}
export function PlaceholdersImageItems(mediaType) {
  switch (mediaType) {
    case MediaType.video:
      return VideosItemPlaceholder;
    case MediaType.presentation:
      return PresentationsItemPlaceholder;
    case MediaType.photo:
      return PhotosItemPlaceholder;
    case MediaType.document:
      return DocumentsItemPlaceholder;
    case MediaType.file:
      return DocumentsItemPlaceholder;
    case MediaType.spreadsheet:
      return SpreadsheetItemPlaceholder;
    case MediaType.audio: // There is no audio Itemplaceholder, the video Itemplaceholder has been put in its place
      return AudiosItemPlaceholder;
    default:
      return PhotosItemPlaceholder;
  }
}

export default MediaType;
