import i18n from '@/libs/i18n';
import moment from 'moment';
import Vue from 'vue';

import flagEs from '@/assets/images/flags/es.png';
import flagCa from '@/assets/images/flags/ca.png';
import flagEn from '@/assets/images/flags/en.png';
import flagBg from '@/assets/images/flags/bg.png';
import flagDk from '@/assets/images/flags/dk.png';
import flagEl from '@/assets/images/flags/el.png';
import flagGe from '@/assets/images/flags/de.png';
import flagAr from '@/assets/images/flags/ar.png';
import flagPt from '@/assets/images/flags/pt.png';
import flagRu from '@/assets/images/flags/ru.png';
import flagCh from '@/assets/images/flags/ch.png';
import flagFr from '@/assets/images/flags/fr.png';
import flagIt from '@/assets/images/flags/it.png';
import flagIn from '@/assets/images/flags/in.png';
import flagJa from '@/assets/images/flags/ja.png';

export default {

  state: {
    currentLocale: 'es',
  },
  getters: {
    currentLocale: (state) => (state.currentLocale),
    // http://www.lingoes.net/en/translator/langcode.htm
    locales: () => [
      {
        value: 'es',
        img: flagEs,
        text: 'Español',
      },
      {
        value: 'ca',
        img: flagCa,
        text: 'Català',
      },
      {
        value: 'en',
        img: flagEn,
        text: 'English',
      },
      {
        value: 'it',
        img: flagIt,
        text: 'Italiano [beta]',
      },
      {
        value: 'fr',
        img: flagFr,
        text: 'Français [beta]',
      },
      {
        value: 'de',
        img: flagGe,
        text: 'Deutsch [beta]',
      },
      {
        value: 'pt',
        img: flagPt,
        text: 'Português [beta]',
      },
      {
        value: 'ru',
        img: flagRu,
        text: 'Русский [beta]',
      },
      {
        value: 'da',
        img: flagDk,
        text: 'Dansk [beta]',
      },
      {
        value: 'bg',
        img: flagBg,
        text: 'Български [beta]',
      },
      {
        value: 'el',
        img: flagEl,
        text: 'ελληνικά [beta]',
      },
      {
        value: 'zh',
        img: flagCh,
        text: '中国人 [beta]',
      },
      {
        value: 'ja',
        img: flagJa,
        text: '日本語 [beta]',
      },
      {
        value: 'ar',
        img: flagAr,
        text: 'عربي [beta]',
      },
      {
        value: 'hi',
        img: flagIn,
        text: 'हिंदी [beta]',
      },
    ],
  },
  mutations: {
    SET_LOCALE(state, newLocale) {
      state.currentLocale = newLocale;
    },
  },
  actions: {
    async setLocale({ commit }, newLocale) {
      commit('SET_LOCALE', newLocale);
      i18n.locale = newLocale;
      Vue.$cookies.set("i18n_redirected", i18n.locale)
      moment.locale(newLocale);
    },
  },

};
