  import store from "@/store";

export default async function OrganizationsListWidgetsFactory() {

  let showTypes = 1;

  showTypes = store.getters.apps;
  let types = [];
  showTypes = showTypes?.apps[32]?.customization?.displayOptions;

  if(showTypes == null || showTypes === ""){
    showTypes = 1;
  }

  if (showTypes === 1) {
    types.push({
      name: "Organizations",
      key: "null",
      namePlural: { es: "Organizaciones" },
    });
  } else if (showTypes === 2) {
    await store.dispatch("getItems", {
      itemType: "organizationTypes",
      storedKey: "organizations",
      customName: "types",
      page: 1,
      perPage: 200,
      requestConfig: {
        modelType: "organization"
      },
    });
    types = store.getters.types?.organizations?.unpaginated || []; 
  } else {
    await store.dispatch("getItems", {
      itemType: "organizationTypes",
      storedKey: "organizations",
      customName: "types",
      page: 1,
      perPage: 200,
      requestConfig: {
        modelType: "organization"
      },
    });
    types = store.getters.types?.organizations?.unpaginated || []; 
    types.push({
      name: "Organizations",
      key: "null",
      namePlural: { es: "Organizaciones" },
    });
  }

  return types.reduce(
    (widgetsMap, type) => ({
      ...widgetsMap,
      [`organization-${type.key}`]: {
        loadComponent: () =>
          import("@/views/apps/organization/widgets/OrganizationsTableWidget.vue"),
        props: { type, listingType: 0 },
      },
    }),
    {}
  );
}
