export const PEOPLE_STORE_MODULE_NAME = 'people';

export const PEOPLE_ACTIONS = {
  fetchTypes: `${PEOPLE_STORE_MODULE_NAME}/fetchTypes`,
  fetchList: `${PEOPLE_STORE_MODULE_NAME}/fetchList`,
};

export const PEOPLE_GETTERS = {
  getByNam: `${PEOPLE_STORE_MODULE_NAME}/getByName`,
  getPage: `${PEOPLE_STORE_MODULE_NAME}/getPage`,
  getTotal: `${PEOPLE_STORE_MODULE_NAME}/getTotal`,
  types: `${PEOPLE_STORE_MODULE_NAME}/types`,
};
