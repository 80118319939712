export const ENTITIES_STORE_MODULE_NAME = 'entities';

export const ENTITIES_ACTIONS = {
  fetchTypes: `${ENTITIES_STORE_MODULE_NAME}/fetchTypes`,
  fetchList: `${ENTITIES_STORE_MODULE_NAME}/fetchList`,
};

export const ENTITIES_GETTERS = {
  getByNam: `${ENTITIES_STORE_MODULE_NAME}/getByName`,
  getPage: `${ENTITIES_STORE_MODULE_NAME}/getPage`,
  getTotal: `${ENTITIES_STORE_MODULE_NAME}/getTotal`,
  types: `${ENTITIES_STORE_MODULE_NAME}/types`,
};
