import {
  CCSPClient,
  CCSPChallengeAnswerKeys,
  CCSPTokenChallengeAnswerKeys,
  CCSPChatRoomType,
  CCSPChallengeType,
  ChatProxy,
  CCSPChatTargetType,
} from '@copernicsw/community-common';
import { version } from '@/config/version';

export default class ChatService {
  constructor({ getToken }) {
    this.clientName = `${process.env.VUE_APP_WS_CHAT_CLIENT_NAME}-${version}`;
    this.protocol = process.env.VUE_APP_WS_CHAT_PROTOCOL;
    this.authTokenGroup = process.env.VUE_APP_WS_CHAT_AUTH_TOKEN_GROUP;
    this.wsChatUrl = process.env.VUE_APP_WS_CHAT_URL;

    this.client = new CCSPClient(this.wsChatUrl);
    this.chatProxy = new ChatProxy(this.client);

    this.getToken = getToken;
  }

  async connect() {
    const token = this.getToken();

    if (!token) {
      throw new Error('Connection Refused, user is not authenticated');
    }

    this.client.addChallengeAnswer({
      [CCSPChallengeAnswerKeys.type]: CCSPChallengeType.token,
      [CCSPTokenChallengeAnswerKeys.tokenGroup]: this.authTokenGroup,
      [CCSPTokenChallengeAnswerKeys.token]: token,
    });

    await this.client.connect(this.clientName, this.protocol);
  }

  close() {
    this.client.close();
  }

  /**
   * Joins a room
   *
   * @param {String} room The room to be joined
   * @param {'collective'|'group'} roomType  The type of the room
   *
   * @returns The full name of the room.
   */
  async joinRoom(room, roomType) {
    if (!Object.keys(CCSPChatRoomType).includes(roomType)) {
      throw new Error(`Invlid value for roomType: [${roomType}]`);
    }

    const { room: fullRoomName } = await this.chatProxy.join(room, CCSPChatRoomType[roomType], true);

    return fullRoomName;
  }

  /**
   * Adds a listener to new messages in a room. It will call before and after listeners.
   *
   * @param {String} fullRoomName
   * @param {({
   *  target: roomFullName,
   *  origin: senderId,
   *  message,
   *  messageID,
   *  timestamp,
   * }) => void } handler The hanlder for new messages
   */
  addNewRoomMessageListener(fullRoomName, handler) {
    this.chatProxy.addListener(`message-${fullRoomName}`, (event) => handler(event));
  }

  /**
   * Adds a listener to new user in a room.
   *
   * @param {String} fullRoomName
   * @param {({
   *  targetName,
   *  event: { users: contacts }
   * }) => void } handler To be called when a new user is added.
   */
  addNewUserInRoomListener(fullRoomName, handler) {
    this.chatProxy.addListener(`listUser-${fullRoomName}`, (event) => { handler(event); });
  }

  /**
   * Lists the users in a room.
   *
   * @param {String} room The room
   * @param {'collective'|'group'} roomType The room type
   * @param {Function} handleUsersEvent To be called when a new user is added.
   * @returns The array of users.
   */
  async listUsers(room, roomType) {
    if (!Object.keys(CCSPChatRoomType).includes(roomType)) {
      throw new Error(`Invlid value for roomType: [${roomType}]`);
    }

    const users = await this.chatProxy.listUsers(room, CCSPChatRoomType[roomType]);

    return users;
  }

  /**
   * Gets the old messages from a room.
   *
   * @param {String} room The room
   * @param {'collective'|'group'} roomType The room type
   * @param {*} beforeDate Before the given date.
   * @param {*} count The limit of the fetch
   * @returns The array of messages.
   */
  async getRoomMessages(room, roomType, beforeDate, count) {
    if (!Object.keys(CCSPChatRoomType).includes(roomType)) {
      throw new Error(`Invlid value for roomType: [${roomType}]`);
    }

    return this.chatProxy
      .getGroupMessages(CCSPChatRoomType[roomType], room, beforeDate, count);
  }

  /**
   * Send message to the given room.
   *
   * @param {String} target The room name or user id
   * @param {Object.keys(CCSPChatTargetType)} targetType
   * @param {String} message
   * @returns The messageID
   */
  async sendMessage(communityKey, target, targetType, message) {
    if (!Object.keys(CCSPChatTargetType).includes(targetType)) {
      throw new Error(`Invlid value for targetType: [${targetType}]`);
    }

    return this.chatProxy.sendMessage(communityKey, target, CCSPChatTargetType[targetType], message);
  }

  /**
   * Returns the user information.
   *
   * @param {String} userId The user id.
   * @returns {{
   *     key: string;
   *     name: string;
   *     username: string;
   *     surname: string;
   *     headline: string;
   *     backgroundUrl: string;
   * }} The user info
   */
  async getUserInfo(userId, communityKey) {
    return this.chatProxy.getUserInfo(userId, communityKey);
  }
}
