import store from "@/store";
import {
  ENTITIES_STORE_MODULE_NAME,
  // ENTITIES_ACTIONS,
} from "@/views/apps/entities/constants/entities-store-constants";
import entitiesStoreModule from "@/views/apps/entities/store/entitiesStoreModule";

export default async function EntitiesListWidgetsFactory() {
  if (!store.hasModule(ENTITIES_STORE_MODULE_NAME)) {
    store.registerModule(ENTITIES_STORE_MODULE_NAME, entitiesStoreModule);
  }
  let showTypes = "";
  showTypes = store.getters.apps;
  let types = [];
  showTypes = showTypes.apps[57]?.customization?.displayOptions;

  await store.dispatch("getItems", {
    itemType: "types",
    storedKey: "AllEntitiesTypes",
    page: 1,
    perPage: 200,
    requestConfig: {
      modelType: "entity",
      getCommunityTypes: 1,
      selectAll: 1
    },
  });

  if (showTypes === 1 || !showTypes) {
    types.push({
      name: "Entities",
      key: "noKey",
      namePlural: { es: "Entities" },
    });
  } else if (showTypes === 2) {
    await store.dispatch("getItems", {
      itemType: "types",
      storedKey: "entities",
      page: 1,
      perPage: 200,
      requestConfig: {
        getCommunityTypes: 1,
        modelType: "entity"
      },
    });
    types = store.getters.types?.entities?.unpaginated || []; 
  } else {
    await store.dispatch("getItems", {
      itemType: "types",
      storedKey: "entities",
      page: 1,
      perPage: 200,
      requestConfig: {
        getCommunityTypes: 1,
        modelType: "entity"
      },
    });
    types = store.getters.types?.entities?.unpaginated || []; 
    types.push({
      name: "Entities",
      key: "noKey",
      namePlural: { es: "Entities" },
    });
  }

  return types.reduce(
    (widgetsMap, type) => ({
      ...widgetsMap,
      [`entity-${type.key}`]: {
        loadComponent: () =>
          import("@/views/apps/entities/widgets/EntitiesListWidget.vue" /* webpackChunkName: "EntitiesListWidget" */),
        props: { type },
      },
    }),
    {}
  );
}
