import store from "@/store";
import {
  PEOPLE_STORE_MODULE_NAME,
  PEOPLE_ACTIONS,
} from "@/views/apps/people/constants/people-store-constants";
import peopleStoreModule from "@/views/apps/people/store/peopleStoreModule";

export default async function PeopleByTypeListWidgetsFactory() {
  if (!store.hasModule(PEOPLE_STORE_MODULE_NAME)) {
    store.registerModule(PEOPLE_STORE_MODULE_NAME, peopleStoreModule);
  }
  let showTypes = "";
  showTypes = store.getters.apps;


  showTypes = showTypes.apps[35]?.customization?.displayOptions;
  /*   const types = await store.dispatch(PEOPLE_ACTIONS.fetchTypes, {}); */
  let types = [];

  await store.dispatch("getItems", {
    itemType: "types",
    storedKey: "AllPeopleTypes",
    page: 1,
    perPage: 200,
    requestConfig: {
      modelType: "people",
      getCommunityTypes: 1,
      selectAll: 1
    },
  });

  if (showTypes === 1) {
    types.push({ name: "People", key: "noKey", namePlural: { es: "People" } });
  } else if (showTypes === 2) {
    await store.dispatch("getItems", {
      itemType: "types",
      storedKey: "people",
      page: 1,
      perPage: 200,
      requestConfig: {
        getCommunityTypes: 1,
        modelType: "people"
      },
    });
    types = store.getters.types?.people?.unpaginated || [];
  } else {
    await store.dispatch("getItems", {
      itemType: "types",
      storedKey: "people",
      page: 1,
      perPage: 200,
      requestConfig: {
        getCommunityTypes: 1,
        modelType: "people"
      },
    });
    types = store.getters.types?.people?.unpaginated || [];
    types.push({ name: "People", key: "noKey", namePlural: { es: "People" } });
  }
  if (!types?.length) {
    return {
      "people-empty": {
        loadComponent: () =>
          import("@/views/apps/people/widgets/PeopleListEmptyWidget.vue" /* webpackChunkName: "PeopleListEmptyWidget" */),
      },
    };
  }

  return types.reduce(
    (widgetsMap, type) => ({
      ...widgetsMap,
      [`people-${type.key}`]: {
        loadComponent: () =>
          import("@/views/apps/people/widgets/PeopleListWidget.vue" /* webpackChunkName: "PeopleListWidget" */),
        props: { type },
      },
    }),
    {}
  );
}
