import Vue from 'vue';
import Vuex from 'vuex';

// Service container
import container from '@/config/container';

// Modules
import chatStoreModule from '@/views/apps/chat/store/chatStoreModule';
import { CHAT_STORE_MODULE_NAME } from '@/views/apps/chat/constants/chat-store-constants';

import app from './app/app.store';
import appConfig from './app-config/appConfig.store';
import verticalMenu from './vertical-menu/verticalMenu.store';
import copernicData from './copernic-data/copernicData.store';
import userData from './user-data/userData.store';
import auth from './auth/auth.store';
import collective from './collective/collective.store';
import locale from './locale/locale.store';
import uiStates from './ui-states/uiStates.store';
import sockets from './sockets/sockets.store';

import notificationsStoreModule from './notifications/notificationsStoreModule';
import { NOTIFICATIONS_STORE_MODULE_NAME } from './notifications/notifications-store-constants';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    copernicData,
    userData,
    auth,
    collective,
    locale,
    uiStates,
    [NOTIFICATIONS_STORE_MODULE_NAME]: notificationsStoreModule,
    [CHAT_STORE_MODULE_NAME]: chatStoreModule,
    sockets
  },
  strict: process.env.DEV,
});

store.$service = container;

export default store;
