import Vue from 'vue';

function getPrefixArray(prefix) {
  const prefixArray = Array.isArray(prefix) ? prefix : [prefix];
  // filter out null and undefined prefixes
  return prefixArray.filter((p) => p != null);
}

function getActualState(state, prefix) {
  const prefixArray = getPrefixArray(prefix);

  let actualState = state;
  prefixArray.every((p) => {
    if (!actualState[p]) {
      actualState = null;
      return false;
    }

    actualState = actualState[p];

    return true;
  });

  return actualState;
}

function initState(state, prefix, forItemStorage = false) {
  const prefixArray = getPrefixArray(prefix);

  let actualState = state;
  prefixArray.forEach((p) => {
    if (!actualState[p]) {
      Vue.set(actualState, p, {});
    }

    actualState = actualState[p];
  });

  if (!actualState.storage) {
    if (forItemStorage) {
      Vue.set(actualState, 'storage', { byKey: {} });
    } else {
      Vue.set(actualState, 'storage', { byKey: {}, items: [], total: 0 });
    }
  } else {
    if (forItemStorage && !actualState.storage.byKey) {
      Vue.set(actualState.storage, 'byKey', {});
    }
    if (!forItemStorage && !actualState.storage.items) {
      Vue.set(actualState.storage, 'items', []);
      Vue.set(actualState.storage, 'total', []);
    }
  }

  return actualState;
}

export function getByKey(key, state, prefix = []) {
  const actualState = getActualState(state, prefix);

  if (!actualState?.storage?.byKey) {
    return null;
  }

  return actualState.storage.byKey[key];
}

export function getPage({ page, perPage }, state, prefix = []) {
  const actualState = getActualState(state, prefix);

  if (!actualState?.storage?.items) {
    return null;
  }

  let response;
  if (page) {
    response = actualState.storage.items.slice((page - 1) * perPage, page * perPage);
  } else {
    response = actualState.storage.items;
  }

  const isIncomplete = response.some((item) => !item);
  if (isIncomplete) {
    // we need to make a new request.
    return null;
  }

  return response.map((key) => getByKey(key, state, prefix));
}

export function getUntilPage({ page, perPage }, state, prefix = []) {
  const actualState = getActualState(state, prefix);

  if (!actualState?.storage?.items) {
    return null;
  }

  const response = actualState.storage.items.slice(0, page * perPage);

  const isIncomplete = response.some((item) => !item);
  if (isIncomplete) {
    // we need to make a new request.
    return null;
  }

  return response.map((key) => getByKey(key, state, prefix));
}

export function getTotal(state, prefix = []) {
  const actualState = getActualState(state, prefix);

  if (!actualState?.storage) {
    return 0;
  }

  return actualState.storage.total;
}

export function storeItem(item, state, prefix = []) {
  const actualState = initState(state, prefix, true);

  Vue.set(actualState.storage.byKey, item.key, item);
}

export function updateTotal(total, state, prefix = []) {
  const actualState = initState(state, prefix);

  actualState.storage.total = total;
}

export function storePage({ data, meta }, state, prefix = []) {
  const actualState = initState(state, prefix);

  if (!actualState.storage.items.length) {
    actualState.storage.items.push(...(new Array(meta ? meta.total > 100 ? 100 : meta.total : data.length).fill(null)));
  }

  const itemKeys = data.map(({ key }) => key);
  actualState.storage.items.splice(meta?.from ? meta.from - 1 : 0, itemKeys.length, ...itemKeys);

  data.forEach((item) => {
    storeItem(item, state, prefix);
  });

  actualState.storage.total = meta ? meta.total : data.length;
}

export function updateItemByKey(key, partial, state, prefix = []) {
  const actualState = getActualState(state, prefix);

  if (!actualState?.storage) {
    return null;
  }

  Vue.set(actualState.storage.byKey, key, {
    ...(actualState.storage.byKey[key] || {}),
    ...partial,
  });

  return actualState.storage.byKey[key];
}

export function prependItem(item, state, prefix = []) {
  const actualState = initState(state, prefix, true);
  actualState.storage.items.unshift(item.key);
  storeItem(item, state, prefix);

  actualState.storage.total += 1;
}
